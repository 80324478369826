import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { customTagEvent } from '../../utils/analytics/utils.js';
import { TFunction } from '../../utils/types.js';
import { myProviders, environment } from '../../app.js';
import { EESaveLead } from '../../utils/api/med-sup.js';
import { enroDRX } from '../../utils/apiHelper/igse-session.js';
// Code needed for dual plan year - do not delete
import expiringPlans from '../../../public/locales/expiring-plans.json';
import DiscountModal from '../discount-modal/discount-modal.js';
import RiderModal from '../rider-modal/rider-modal.js';
import EnrollWarningModal from '../enroll-warning-modal/enroll-warning-modal.js';
import enOnlyContent from '../../../public/locales/en-only-content.json';
import enrollWarningPlans from '../../../public/locales/enroll-warning-plans.json';
import {
  IMedicarePlanPlanDataField,
  IPdpPlanBenefit,
  IProductDetail,
  IProductDetailValue,
  IProvider,
  IRateDetail,
  IRider,
  ISuppPlanBenefit,
  IPlanDataField,
  IExtraDataObj,
} from '../../utils/api/interfaces.js';
// Hooks
import useUserData from '../../hooks/use-user-data.js';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import useDrugSelector from '../../hooks/use-drug-selector.js';
import useErrorStates from '../../hooks/use-error-states.js';
import './plan-card.scss';

interface IPlan {
  ENONLY_apiValue: string;
  planName: string;
  benefits: Array<string>;
  benefitsIntro: string;
  detailsLink: string;
  order: number;
  ContractID?: string;
  PlanID?: string;
  SegmentID?: string;
  PlanDataFields?: Array<IPlanDataField>;
  ExtraData?: Array<IExtraDataObj>;
}

interface PlanCardProps {
  data: any;
  isEligibleBefore?: boolean;
  maPlansLength?: number;
  planType: string;
  providers?: Array<IProvider>;
  handleSuppEnrollSpinner?: TFunction;
}

interface IBenefitsInfo {
  isPrescription?: boolean;
  isDental?: boolean;
  isHearing?: boolean;
  isVision?: boolean;
  isOTC?: boolean;
  isFitness?: boolean;
  primaryCare?: string;
  specialistCare?: string;
}

type IPlanCardPlanData = IPlan | ISuppPlanBenefit;

interface IPlanCardState {
  plan?: IPlanCardPlanData;
  compareHtml?: JSX.Element;
  allRates?: Array<IRider>;
  annualRateNoDiscount?: number;
  benefits: IBenefitsInfo;
  dataCustom?: string;
  hasDiscounts?: boolean;
  isExpired?: boolean;
  lowestRateObj?: IRateDetail;
  monthlyMultiplier: number;
  premiumAfterRider?: number;
  ratesObj?: Array<IRateDetail>;
  riderArray: Array<IProductDetail>;
  selectedContractId?: string;
  selectedId?: string;
  selectedPlanId?: string;
  selectedPlanType?: number;
  selectedProvider?: string;
  selectedProviderID?: string;
  selectedSegmentId?: string;
  selfEnrollDiscount?: number;
  showRiderModal?: boolean;
  suppFormData?: { [key: string]: Object };
  discountHtml?: JSX.Element;
  discountIncludesHtml?: JSX.Element;
  supRate?: JSX.Element | string;
  monthlyPremium?: Array<string>;
  planCost: number;
  compareToggleEvent?: Event;
  enrollWarningContent?: string;
  isRxpiring?: boolean;
  hideEnroll: string;
}

interface IWarningObj {
  block: string[];
  medicaid: string[];
  diabetes: string[];
}

const dataCustomVar = 'prop13:ac23989^eVar119:CAKTD-4646^eVar120:shop-plans-compare-all-plans';

const PlanCard: React.FC<PlanCardProps> = (props: PlanCardProps) => {
  const spdlanding = environment.endpoints.spdlanding;
  const spdedit = environment.endpoints.spdedit;
  const spdreturnpath = environment.endpoints.spdreturnpath;
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const content = useTranslation('plan-results-content');
  const suppContent = useTranslation('plan-results-supp-content');
  const userData = useUserData();
  const pharmacyData = usePharmacyData();
  const drugSelectorState = useDrugSelector();
  const hasErrorState = useErrorStates();
  const [planCardState, setPlanCardState] = useState<IPlanCardState>({
    benefits: {
      isPrescription: false,
      isDental: false,
      isHearing: false,
      isVision: false,
      isOTC: false,
      isFitness: false,
      primaryCare: '',
      specialistCare: '',
    },
    hasDiscounts: true,
    isExpired: false,
    monthlyMultiplier: 0.0833,
    riderArray: [],
    selectedProvider: '',
    showRiderModal: false,
    planCost: 0,
    enrollWarningContent: '',
    hideEnroll: '',
  });
  let rootRef = useRef<HTMLDivElement>(null);

  const updateBenefits = (updateState: IPlanCardState) => {
    if (props.planType !== 'supp') {
      let benefitsInfo: IBenefitsInfo = {};
      props.data.PlanType === 1 || props.data.PlanType === 2
        ? (benefitsInfo.isPrescription = true)
        : (benefitsInfo.isPrescription = false);
      props.data.PlanDataFields.forEach((benefit: IMedicarePlanPlanDataField) => {
        switch (benefit.Name) {
          case 'Dental Services (In Network)':
            benefitsInfo.isDental = true;
            break;
          case 'Hearing Services (In Network)':
            benefitsInfo.isHearing = true;
            break;
          case 'Eye Exams (In Network)':
            benefitsInfo.isVision = true;
            break;
          case 'Over-the-Counter Items (In Network)':
            benefitsInfo.isOTC = true;
            break;
          case 'Fitness Benefit (In Network)':
            benefitsInfo.isFitness = true;
            break;
          case 'Doctor Office Visits (In Network)':
            benefitsInfo.primaryCare = benefit.Description;
            break;
          case 'Doctor Specialty Visit (In Network)':
            benefitsInfo.specialistCare = benefit.Description;
            break;
          default:
            break;
        }
      });
      updateState.benefits = benefitsInfo;
    }
  };

  const providernavigation = (plan: IPlan) => {
    const event = new CustomEvent('providerpull', {
      bubbles: true,
      composed: true,
      cancelable: true,
    });
    document?.dispatchEvent(event);

    let fullplan = `${plan.ContractID}${plan.PlanID}${plan.SegmentID}`;

    (rootRef.current?.querySelector(`#providerForm-${fullplan}`) as HTMLFormElement)?.submit();
  };

  const setImpressionStr = (plan: IPlanCardPlanData) => {
    if (props.planType !== 'supp') {
      return `shop-plans-card-list^^^${props.data.PlanName}:${content.t(`${[props.planType]}.resultsHeader`)}^${
        myProviders.data.providers?.length ? `providerInfo` : ''
      }^${props.data.ContractID + '-' + props.data.PlanID + '-' + props.data.SegmentID}:No provider given^premiumCost`;
    }
    const monthlyPremium = formatPremium(planCardState.planCost, true);
    return `shop-plans-card-list^^^${plan.ENONLY_apiValue}:${suppContent.t('misc.resultsHeader')}^^${
      props.data.productID
    }:No provider given^${monthlyPremium}`;
  };

  const modalEnrollToggle = (event: Event) => {
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData?.year?.toString(),
    );
    const fullPlan = `${props.data.ContractID}-${props.data.PlanID}-${props.data.SegmentID}`;
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });
    if (warnModal.length > 0) {
      setPlanCardState((prevState) => ({
        ...prevState,
        enrollWarningContent: content.t(`misc.enrollWarning${warnModal}`),
      }));
      (document.querySelector(`#enroll-warning-modal-${fullPlan}`) as any)?.show();
    } else {
      const thisCard = rootRef.current;
      sendFloodLight(event);
      rootRef.current?.dispatchEvent(
        new CustomEvent('passEnroll', {
          bubbles: true,
          composed: true,
          cancelable: true,
          detail: {
            selectedId: planCardState.selectedId,
            selectedPlanId: planCardState.selectedPlanId,
            selectedContractId: planCardState.selectedContractId,
            selectedSegmentId: planCardState.selectedSegmentId,
            dataCustom: planCardState.dataCustom,
            dataImpression: thisCard?.getAttribute('data-product-custom')?.length
              ? thisCard?.getAttribute('data-product-custom')
              : thisCard?.getAttribute('data-impression'),
            selectedPlanType: planCardState.selectedPlanType,
            isExpired: planCardState.isExpired,
          },
        }),
      );
    }
  };

  // // Code needed for dual plan year
  const setYear = () => {
    const thisCard = rootRef.current;
    document?.dispatchEvent(
      new CustomEvent('sendYear', {
        bubbles: true,
        composed: true,
        cancelable: true,
        detail: {
          year: 2025,
          dataProductCustom: thisCard?.getAttribute('data-custom-product')?.length
            ? thisCard?.getAttribute('data-product-custom')
            : thisCard?.getAttribute('data-impression'),
        },
      }),
    );
  };

  const sendFloodLight = (event: Event) => {
    const target = event.currentTarget as HTMLElement;
    customTagEvent(target, `${props.planType}-plan-click`);
  };

  const renderPremium = (plan: IPlanCardPlanData) => {
    const isCurrentYear = userData.year?.toString() === currentYear.toString();
    const fullPlan = `${props.data.ContractID}-${props.data.PlanID}-${props.data.SegmentID}`;
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData!.year!.toString(),
    );
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });

    let renderEnroll = <></>;
    switch (true) {
      case (isCurrentYear && content.t('misc.currentYearShowEnroll') === 'true') ||
        (environment?.showAllEnroll && warnModal.length < 1):
      case (!isCurrentYear && content.t('misc.nextYearShowEnroll') === 'true') ||
        (environment?.showAllEnroll && warnModal.length < 1):
        renderEnroll = (
          <div className="enroll">
            <leaf-button
              data-en-content={enOnlyContent.plansView.enrollModalOpen}
              data-custom={`prop13:ac23983^eVar119:CSTD-3125^eVar120:shop-plans-enroll-in-plan-drx-exit^eVar160:Exit*${environment.endpoints.drxUrl}`}
              size="sm"
              variant="primary"
              inverted
              onClick={(event: Event) => {
                sendFloodLight(event);
                EnrollDRX(props.data.ID);
              }}
            >
              {content.t('misc.enroll')}
            </leaf-button>
          </div>
        );
        break;
      case (isCurrentYear && content.t('misc.currentYearShowEnrollModal') === 'true') || environment?.showAllEnroll:
      case isCurrentYear && content.t('misc.currentYearShowEnroll') === 'true' && warnModal.length > 0:
      case (!isCurrentYear && content.t('misc.nextYearShowEnrollModal') === 'true') || environment?.showAllEnroll:
        renderEnroll = (
          <div className="enroll">
            <leaf-button
              isModalToggle={true}
              data-modal-id={warnModal.length > 0 ? `enroll-warning-modal-${fullPlan}` : 'EnrollThisPlan'}
              onClick={modalEnrollToggle}
              size="sm"
              variant="primary"
              inverted
              data-en-content={enOnlyContent.plansView.enrollModalOpen}
              data-custom="prop13:ac24773^eVar119:CSTD-3125^eVar120:link-shop-plans-enrollment-confirmation-modal-open"
            >
              {content.t('misc.enroll')}
            </leaf-button>
          </div>
        );
    }

    // Code needed for dual plan year
    const renderExpiring = planCardState?.isExpired ? `${content.t('misc.aepExpiring')}` : '';
    const renderEnrollInfo = () => {
      if (
        userData.year?.toString() !== '2025' &&
        content.t('misc.aepEnrollmentCurrentYearInfo').indexOf('hidden') < 0
      ) {
        return (
          <p className="aep-enrollment-info">
            {HTMLReactParser(content.t('misc.aepEnrollmentCurrentYearInfo'))} <strong>{renderExpiring}</strong>
            <br />
            {userData.year?.toString() !== '2025' &&
              content.t('misc.aepEnrollmentCurrentYearInfo').indexOf('hideToggle') < 0 && (
                <>
                  <leaf-button-link
                    onClick={setYear}
                    data-custom={`prop13:ac24769^eVar51:${content.t(
                      'ma.resultsHeader',
                    )}^eVar119:CSTD-3124^eVar120:shop-plans-view-next-year-plans`}
                  >
                    {content.t('misc.aepCurrentYearLink')}
                  </leaf-button-link>
                  <br />
                </>
              )}
          </p>
        );
      } else if (userData.year?.toString() === '2025') {
        return <p className="aep-enrollment-info">{HTMLReactParser(content.t('misc.aepEnrollmentNextYearInfo'))}</p>;
      }
      return;
    };
    if (props.planType !== 'supp') {
      return (
        <>
          <p className="premium">
            {content.t('misc.monthlyPremium')}&nbsp;
            <span className="number">${formatPremium(planCardState.planCost)}</span>
          </p>
          {/* Code needed for dual plan year */}
          {renderEnrollInfo()}
          <leaf-flex-container>
            <leaf-button
              data-custom="prop13:ac23982^eVar119:CSTD-3125^eVar120:shop-plans-view-details"
              variant="secondary"
              size="sm"
              onClick={viewDetails}
              inverted
            >
              {content.t('misc.viewDetails')}
            </leaf-button>
            {renderEnroll}
          </leaf-flex-container>
        </>
      );
    }

    return (
      <>
        <p className="premium">
          {planCardState.monthlyPremium && planCardState.monthlyPremium[0]}
          <span className="number">${formatPremium(planCardState.planCost)}</span>
          {planCardState.monthlyPremium && planCardState.monthlyPremium[1]}
        </p>
        {planCardState.discountHtml}
        <leaf-flex-container>
          <leaf-button
            variant="secondary"
            data-custom="prop13:ac23982^eVar120:shop-plans-view-details"
            size="sm"
            target="_blank"
            href={plan.detailsLink}
            inverted
          >
            {content.t('misc.viewDetails')}
          </leaf-button>
          <div className="enroll">
            <leaf-button
              data-custom={`prop13:ac23984^eVar119:CAKTD-4646^eVar120:shop-plans-apply-now-expressenroll-exit^eVar160:Exit*${environment.endpoints.eeEnroll.replaceAll(
                ':',
                '*',
              )}`}
              size="sm"
              variant="primary"
              inverted
              onClick={(event: Event) => {
                medSupEnroll();
                sendFloodLight(event);
              }}
            >
              {content.t('misc.apply')}
            </leaf-button>
          </div>
        </leaf-flex-container>
      </>
    );
  };

  const getBenefitsIcon = (benefitsIncluded?: boolean) => {
    let iconHTML: JSX.Element;
    if (benefitsIncluded) {
      iconHTML = (
        <leaf-icon
          set="system"
          class="leaf-u-color-bg-accent-2-xstrong"
          name="check-circle"
          label={content.t('misc.included')}
        ></leaf-icon>
      );
    } else {
      iconHTML = (
        <leaf-icon
          set="system"
          name="close"
          class="leaf-u-color-bg-error-strong"
          label={content.t('misc.notIncluded')}
        ></leaf-icon>
      );
    }
    return iconHTML;
  };

  const renderBenefits = useCallback(
    (plan: IPlanCardPlanData) => {
      if (props.planType !== 'supp' && props.data.PlanType !== 1) {
        return (
          <div className="benefits-section">
            {props.data.PlanName.indexOf('D-SNP') > -1 && (
              <div className="dsnp-text">
                <strong>{content.t('ma.dsnpText')}</strong>
              </div>
            )}
            <leaf-list iconlist columns="1">
              <p className="benefits-intro leaf-u-typography-title-small" slot="content">
                {content.t('ma.benefitsIntro')}
              </p>

              <li>
                {getBenefitsIcon(planCardState.benefits.isPrescription)}
                {content.t('ma.prescriptionDrugs')}
              </li>
              <li>
                {getBenefitsIcon(planCardState.benefits.isDental)}
                {content.t('ma.dental')}
              </li>
              <li>
                {getBenefitsIcon(planCardState.benefits.isVision)}
                {content.t('ma.vision')}
              </li>
              <li>
                {getBenefitsIcon(planCardState.benefits.isHearing)}
                {content.t('ma.hearing')}
              </li>
              <li>
                {getBenefitsIcon(planCardState.benefits.isOTC)}
                {content.t('ma.otc')}
              </li>
              <li>
                {getBenefitsIcon(planCardState.benefits.isFitness)}
                {content.t('ma.fitness')}
              </li>
            </leaf-list>
          </div>
        );
      } else if (props.data.PlanType === 1) {
        return (
          <div className="benefits-section">
            {userData.year.toString() === '2024' && props.data.PlanName.includes('Secure') && (
              <div className="dsnp-text">{HTMLReactParser(content.t('pdp.secureRenameContent'))}</div>
            )}
            <leaf-list iconlist columns="1">
              <p className="benefits-intro leaf-u-typography-title-small" slot="content">
                {content.t('pdp.benefitHighlights')}
              </p>
              {(content.t('pdpPlanBenefits', { returnObjects: true }) as any).map(
                (planData: IPdpPlanBenefit) =>
                  props.data.PlanName.includes(planData.plan) &&
                  Object.values(planData.benefits).map(
                    (benefit: string, index: number) =>
                      ((userData.year?.toString() == '2024' &&
                        ((planData.plan == 'Extra' && index != 3 && index != 7) || planData.plan != 'Extra') &&
                        ((planData.plan == 'Saver' && index != 4) || planData.plan != 'Saver') &&
                        ((planData.plan == 'Secure' && index != 4 && planData.plan == 'Secure' && index != 5) ||
                          planData.plan != 'Secure')) ||
                        (userData.year?.toString() == '2025' &&
                          ((planData.plan == 'Assurance' && index != 4 && index != 5) ||
                            (planData.plan == 'Extra' && index != 3) ||
                            (planData.plan != 'Assurance' && planData.plan != 'Extra')))) && (
                        <li key={index}>
                          <leaf-icon
                            set="system"
                            name="check-circle"
                            class="leaf-u-color-bg-accent-2-xstrong"
                          ></leaf-icon>
                          {HTMLReactParser(benefit)}
                        </li>
                      ),
                  ),
              )}
            </leaf-list>
          </div>
        );
      } else if (props.planType === 'supp' && plan.benefits) {
        return (
          <leaf-list iconlist columns="1" className="benefits-section supp-benefits">
            <p slot="content" className="benefits-intro leaf-u-typography-title-small">
              {plan.benefitsIntro}
            </p>
            {Object.values<string>(plan.benefits).map((benefit) => (
              <li key={benefit}>
                <leaf-icon set="system" name="check-circle" class="leaf-u-color-bg-accent-2-xstrong"></leaf-icon>
                {benefit}
              </li>
            ))}
          </leaf-list>
        );
      }
      return;
    },
    [props.planType, props.data.planType, planCardState.benefits],
  );

  const renderDrugInfo = useCallback(() => {
    let color: string;
    let returnHtml: JSX.Element;
    const drugVar =
      pharmacyData.selectedPharmacyName === 'Mail Order'
        ? props.data.MailOrderAnnualCost
        : props.data.EstimatedAnnualDrugCost;
    if (!planCardState.benefits.isPrescription) {
      color = 'leaf-u-background-warning align-content-center';
      returnHtml = <p>{content.t('ma.noDrugCoverage')}</p>;
    } else if (drugSelectorState.dosageList.length <= 0) {
      color = 'product-card-background-light-orange align-content-center';
      returnHtml = (
        <p className="add-drug-link">
          {/* Component is not designed to match mockup */}
          <leaf-button-link
            classes="leaf-u-link--action leaf-u-link--no-underline"
            type="action"
            onClick={() => showExtraHelp()}
            data-custom="prop13:ac23985^eVar119:CSTD-3133^eVar120:shop-plans-add-rx-estimate"
          >
            {content.t('misc.addDrugs')}
          </leaf-button-link>
        </p>
      );
    } else {
      color = 'product-card-background-light-orange';
      returnHtml = (
        <>
          <p>
            {content.t('misc.drugCost')}
            <br />
            {content.t('misc.fullMonthlyCost')}
            <br />
            &nbsp;<span className="number">${drugVar.toFixed(2)}</span>
          </p>
          <leaf-button-link
            classes="leaf-u-link--no-underline"
            class="edit-drug"
            onClick={editInfo}
            data-custom="prop13:ac23986^eVar119:CSTD-3133^eVar120:shop-plans-edit-rx-estimate"
          >
            {content.t('misc.edit')}
            <leaf-icon set="system" name="keyboard-arrow-right"></leaf-icon>
          </leaf-button-link>
        </>
      );
    }

    if (props.planType !== 'supp') {
      return (
        <div slot={props.planType !== 'pdp' ? 'drug-info' : 'pdp-drug-info'} className={color}>
          {returnHtml}
        </div>
      );
    }

    return null;
  }, [
    props.planType,
    props.data.MailOrderAnnualCost,
    props.data.EstimatedAnnualDrugCost,
    planCardState.benefits.isPrescription,
    pharmacyData.selectedPharmacyName,
  ]);

  const openProviderRemoveModal = async (event: CustomEvent) => {
    const currentTarget = event.currentTarget as HTMLElement;
    setPlanCardState((prevState) => ({
      ...prevState,
      selectedProvider: currentTarget.dataset.providername,
      selectedProviderID: currentTarget.dataset.providerid,
    }));
    document?.dispatchEvent(
      new CustomEvent('showProviderRemoveModal', {
        bubbles: true,
        composed: true,
        detail: {
          target: currentTarget?.shadowRoot?.querySelector('button'),
          selectedProvider: currentTarget.dataset.providername || '',
          selectedProviderID: (currentTarget as HTMLElement).dataset.providerid || '',
          dataImpression: rootRef.current?.getAttribute('data-product-custom'),
        },
      }),
    );
  };

  const renderProviderDetails = useCallback(
    (plan: IPlan) => {
      const returnHTML: Array<JSX.Element> = [];
      props.providers?.forEach((provider: IProvider, index) => {
        const planCovered = !provider.plans
          ? false
          : provider.plans.find((contract: string) => {
              return (
                contract === `${plan.ContractID}${plan.PlanID}${plan.SegmentID}` ||
                contract === `${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`
              );
            });
        let providerCoverageHTML;
        if (planCovered)
          providerCoverageHTML = <p className="coverage-info covered">{content.t('providerInfo.coveredText')}</p>;
        else
          providerCoverageHTML = (
            <p className="coverage-info not-covered">{content.t('providerInfo.notCoveredText')}</p>
          );
        returnHTML.push(
          <div className="provider-data" slot="provider" key={index}>
            <div>
              {providerCoverageHTML}
              <h5>{provider.providerFullName}</h5>
              <p className="provider-address">
                {provider.address1 ?? ''} {provider.address2 ?? ''} {provider.city ?? ''} {provider.state ?? ''}{' '}
                {provider.zipCode ?? ''}
                &nbsp;
                <chc-phone-number
                  number={provider.phone}
                  separator="-"
                  parentheses=""
                  campaign-opt-in
                ></chc-phone-number>
              </p>
            </div>
            <div className="remove-btn-wrapper">
              <leaf-button-link
                classes="leaf-u-link--no-underline"
                onClick={openProviderRemoveModal}
                data-providerid={provider.olpdProviderNetworksAddressId}
                data-providername={provider.providerFullName}
                data-custom="prop13:ac24766^eVar119:CSTD-3127^eVar120:link-shop-plans-remove-provider-team-modal-open"
              >
                {content.t('providerInfo.removeLinkText')}
                <leaf-icon
                  set="system"
                  name="trash"
                  label={content.t('providerInfo.removeAriaLabel')}
                  slot="icon-after"
                ></leaf-icon>
              </leaf-button-link>
            </div>
          </div>,
        );
      });
      return returnHTML;
    },
    [props.providers],
  );

  const renderProviderInfo = useCallback(
    (plan: IPlan) => {
      if (props.providers && props.providers.length > 0) {
        let coverageCount = 0;
        props.providers.forEach((provider: IProvider) => {
          const planCovered = !provider.plans
            ? false
            : provider.plans.find((contract: string) => {
                return (
                  contract === `${plan.ContractID}${plan.PlanID}${plan.SegmentID}` ||
                  contract === `${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`
                );
              });
          if (planCovered) coverageCount++;
        });
        return (
          <>
            {providerForm(plan)}
            <div className="covered-providers">
              <span>
                {content
                  .t('providerInfo.providerCoveredText')
                  .replace('${covered}', coverageCount.toString())
                  .replace('${total}', props.providers.length.toString())}
              </span>
              <leaf-button-link
                classes="leaf-u-link--no-underline"
                data-custom="prop13:ac24765^eVar119:CSTD-3127^eVar120:shop-plans-edit-provider-team-link"
                onClick={() => providernavigation(plan)}
              >
                {content.t('providerInfo.editLinkText')}
                <leaf-icon
                  set="system"
                  name="keyboard-arrow-right"
                  label={content.t('providerInfo.editAriaLabel')}
                  data-custom="prop13:ac24765^eVar119:CSTD-3127^eVar120:shop-plans-edit-provider-team-link"
                ></leaf-icon>
              </leaf-button-link>
            </div>
            {renderProviderDetails(plan)}
          </>
        );
      }
      return (
        <>
          {providerForm(plan)}
          <p className="providers-notselected">
            <leaf-button-link
              classes="leaf-u-link--no-underline"
              onClick={() => providernavigation(plan)}
              data-custom="prop13:ac24764^eVar119:CSTD-3127^eVar120:shop-plans-build-provider-team-link"
            >
              {content.t('providerInfo.checkProviderLabel')}
            </leaf-button-link>
          </p>
        </>
      );
    },
    [props.planType, props.providers],
  );

  const formatPremium = (number: Number, returnValue: boolean = false) => {
    let premiumValue: JSX.Element | string;
    const splitNum = number.toFixed(2).toString().split('.');
    if (!returnValue) {
      premiumValue = (
        <>
          {splitNum[0]}
          <sup>.{splitNum[1]}</sup>
        </>
      );
    } else {
      premiumValue = `${splitNum[0]}.${splitNum[1]}`;
    }
    return premiumValue;
  };

  const viewDetails = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/plan-details';
    urlObj.searchParams.set('PlanID', props.data.ID);
    urlObj.searchParams.set('PlanYear', props.data.PlanYear);

    if (window.location.hash) {
      urlObj.hash = '';
      urlObj.searchParams.set('previousAnchor', window.location.hash.substring(1));
    }

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path);
  };

  const editInfo = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/prescriptions/view-list';

    const path = urlObj.href.replace(urlObj.origin, '');
    const pathName = window.location.href.replace(window.location.origin, '');
    navigate(path, { state: { pathname: pathName } });
  };

  const showExtraHelp = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/extra-help';

    const path = urlObj.href.replace(urlObj.origin, '');
    const pathName = window.location.href.replace(window.location.origin, '');
    navigate(path, { state: { pageNumber: 1, pathname: pathName } });
  };

  const updatePremium = (premiumAfterRider: number) => {
    if (premiumAfterRider) {
      setPlanCardState((prevState) => ({
        ...prevState,
        premiumAfterRider,
        supRate: formatPremium(premiumAfterRider),
        planCost: premiumAfterRider,
      }));
    }
  };

  const medSupEnroll = () => {
    props.handleSuppEnrollSpinner?.();
    EESaveLead({
      firstName: userData.firstName,
      lastName: userData.lastName,
      zipCode: userData.location?.zip,
      gender: userData.gender,
      dateOfBirth: userData.DOB,
      userAge: userData.age,
      campaignId: userData.campaign,
      userCid: userData.cid,
      productId: props.data.productID,
      entityNotes: props.data.productInformation.productName,
      querypassthrough: userData.query,
    }).catch(() => {
      hasErrorState.setApiDownError(true);
      props.handleSuppEnrollSpinner?.();
    });
  };

  const providerForm = (plan: IPlan) => {
    const providerarray: Array<JSX.Element> = [];

    if (myProviders.data.providers?.length > 0) {
      myProviders.data.providers.forEach((provider: IProvider, index: number) => {
        providerarray.push(
          <input type="hidden" name="providerIds" value={provider.olpdProviderNetworksAddressId} key={index} />,
        );
      });
    }

    let fullplan = `${plan.ContractID}${plan.PlanID}${plan.SegmentID}`;

    let providerActionEndpoint = myProviders.data.providers?.length > 0 ? spdedit : spdlanding;

    return (
      <form target="_blank" action={providerActionEndpoint} method="GET" id={`providerForm-${fullplan}`}>
        <input type="hidden" name="igseSessionIdentifier" value={userData.session} />
        <input type="hidden" name="consumerCode" value="HDC061" />
        <input type="hidden" name="searchLocation" value={userData.location?.zip} />
        <input type="hidden" name="plan" value={fullplan} />
        <input type="hidden" name="returnHost" value={spdreturnpath} />
        {providerarray}
      </form>
    );
  };

  const EnrollDRX = (data: string) => {
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData!.year!.toString(),
    );
    const fullPlan = `${props.data.ContractID}-${props.data.PlanID}-${props.data.SegmentID}`;
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });
    if (warnModal.length > 0) {
      setPlanCardState((prevState) => ({
        ...prevState,
        enrollWarningContent: content.t(`misc.enrollWarning${warnModal}`),
      }));
      (document?.querySelector(`#enroll-warning-modal-${fullPlan}`) as any)?.show();
    } else {
      enroDRX({
        session: userData,
        pharmacyList: pharmacyData.getPharmacyList(),
        selectedPlan: data,
      }).catch(() => {
        hasErrorState.setApiDownError(true);
      });
    }
  };

  // Code needed for dual plan year - donot delete
  useEffect(() => {
    if (props.data) {
      setPlanCardState((prevState) => ({ ...prevState, isExpired: false }));
      if (props.data.PlanType && props.data.PlanType !== 1) {
        const matchedPlan = expiringPlans.plans.find(
          (expiringPlan) =>
            expiringPlan.ContractID === props.data.ContractID &&
            expiringPlan.PlanID === props.data.PlanID &&
            expiringPlan.SegmentID === props.data.SegmentID,
        );
        planCardState.isExpired = Boolean(matchedPlan);
        setPlanCardState((prevState) => ({ ...prevState, isExpired: Boolean(matchedPlan) }));
      } else if (props.data.PlanName && props.data.PlanName.includes('Essential')) {
        setPlanCardState((prevState) => ({ ...prevState, isExpired: true }));
      }
    }
  }, [props.data]);

  useEffect(() => {
    document.addEventListener('modalEnrolltoggle', modalEnrollToggle);
    const updateState = { ...planCardState };
    updateBenefits(updateState);
    let compareHtml: JSX.Element;
    if (props.planType === 'supp') {
      suppContent.t('planBenefits', { returnObjects: true }).forEach((planData: ISuppPlanBenefit) => {
        if (props.data.productInformation.productName.includes(planData.ENONLY_apiValue)) {
          updateState.plan = planData;
        }
      });
      compareHtml = (
        <a
          className="leaf-u-link--external"
          target="_blank"
          href={suppContent.t('stateInfo.compareLink')}
          data-custom={dataCustomVar}
        >
          {suppContent.t('misc.compareAllPlans')}
        </a>
      );
      updateState.riderArray = props.data.productDetails.filter((el: IProductDetail) => el.name.includes('RIDER'));
      // PREMIUM
      // TODO: call ratequote api here to get supp plan rates. We won't need the looping logic as the api will return the correct product rates
      const productRiderId = props.data.productDetails
        .find((el: IProductDetail) => el.type === 'coverageRider' && !el.name.includes('rider'))!
        .productDetailValues.find((el: IProductDetailValue) => el.name === 'lifeProCoverageRiderID');
      const riderObj = props.data.rateQuote.find((el: IRider) => el.riderId === productRiderId?.value);
      const ratesUnderwriting = riderObj?.rateDetails;
      updateState.ratesObj = ratesUnderwriting; //For passing to discount modal
      const noHouseHoldDiscount = ratesUnderwriting!.find(
        (el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=NO',
      );
      if (!noHouseHoldDiscount || (ratesUnderwriting?.length === 1 && noHouseHoldDiscount)) {
        // States that don't offer discounts
        updateState.annualRateNoDiscount = ratesUnderwriting && ratesUnderwriting[0].rateAmount;
        updateState.hasDiscounts = false;
      } else {
        if (['ID'].includes(userData.location!.state)) {
          updateState.hasDiscounts = false;
        }
        updateState.annualRateNoDiscount = noHouseHoldDiscount.rateAmount;
      }
      updateState.monthlyMultiplier = props.data.productDetails.find((el: IProductDetail) => el.name === 'pac-monthly')
        ? props.data.productDetails.find((el: IProductDetail) => el.name === 'pac-monthly')!.productDetailValues[0]
            .value
        : 0.0833;
      updateState.lowestRateObj = ratesUnderwriting && ratesUnderwriting[0];
      if (ratesUnderwriting && ratesUnderwriting.length > 1) {
        for (let i = 1; i <= ratesUnderwriting.length - 1; i += 1) {
          if (['FL', 'KS', 'WA', 'ND', 'PA', 'OH'].includes(userData.location!.state)) {
            updateState.lowestRateObj!.rateAmount < ratesUnderwriting[i].rateAmount &&
            [
              'HOUSEHOLD DISCOUNT=LWS AND HHD',
              'HOUSEHOLD DISCOUNT=LWS ONLY',
              'HOUSEHOLD DISCOUNT=YES',
              'HOUSEHOLD DISCOUNT=NO',
            ].includes(updateState.lowestRateObj?.keyDefinition || '')
              ? //eslint-disable-next-line no-self-assign
                (updateState.lowestRateObj = updateState.lowestRateObj)
              : (updateState.lowestRateObj = ratesUnderwriting[i]);
          } else if (['CT', 'NJ'].includes(userData.location!.state)) {
            updateState.lowestRateObj!.rateAmount < ratesUnderwriting[i].rateAmount &&
            ['HOUSEHOLD DISCOUNT=YES', 'HOUSEHOLD DISCOUNT=NO'].includes(updateState.lowestRateObj!.keyDefinition!)
              ? //eslint-disable-next-line no-self-assign
                (updateState.lowestRateObj = updateState.lowestRateObj)
              : (updateState.lowestRateObj = ratesUnderwriting[i]);
          } else if (['ID'].includes(userData.location!.state)) {
            updateState.lowestRateObj = noHouseHoldDiscount;
          } else if (['IA'].includes(userData.location!.state)) {
            updateState.lowestRateObj!.rateAmount < ratesUnderwriting[i].rateAmount &&
            ['HOUSEHOLD DISCOUNT=SE AND HHD'].includes(updateState.lowestRateObj!.keyDefinition!)
              ? //eslint-disable-next-line no-self-assign
                (updateState.lowestRateObj = updateState.lowestRateObj)
              : (updateState.lowestRateObj = ratesUnderwriting[i]);
          } else {
            updateState.lowestRateObj!.rateAmount < ratesUnderwriting[i].rateAmount
              ? //eslint-disable-next-line no-self-assign
                (updateState.lowestRateObj = updateState.lowestRateObj)
              : (updateState.lowestRateObj = ratesUnderwriting[i]);
          }
        }
      }
      const monthlyRate = updateState.lowestRateObj!.rateAmount * updateState.monthlyMultiplier;
      if (ratesUnderwriting!.find((el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE ONLY')) {
        updateState.selfEnrollDiscount =
          (updateState.annualRateNoDiscount! -
            ratesUnderwriting!.find((el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE ONLY')!
              .rateAmount) *
          updateState.monthlyMultiplier;
      }
      const convertPercent = (keyDefinition: Array<string>) => {
        const base = updateState.ratesObj?.find((el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=NO');
        const percentMath = (rateAmount: number) => {
          return Math.round(100 - (rateAmount / base!.rateAmount) * 100);
        };
        let finalVal = 0;
        const isLowestSeHhd = updateState.lowestRateObj?.keyDefinition === 'HOUSEHOLD DISCOUNT=SE AND HHD';
        const isLowestLwsHhd = updateState.lowestRateObj?.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS AND HHD';
        const hasHhdOnly = updateState.ratesObj?.find(
          (el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=YES',
        );
        keyDefinition.forEach((key) => {
          if (key === 'HOUSEHOLD DISCOUNT=YES' && !hasHhdOnly && updateState.ratesObj && isLowestSeHhd) {
            const se = updateState.ratesObj.find(
              (el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE ONLY',
            );
            const seHhd = updateState.ratesObj.find(
              (el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=SE AND HHD',
            );
            const hhd = seHhd!.rateAmount - se!.rateAmount + base!.rateAmount;
            finalVal += percentMath(hhd);
          } else if (key === 'HOUSEHOLD DISCOUNT=YES' && !hasHhdOnly && updateState.ratesObj && isLowestLwsHhd) {
            const lws = updateState.ratesObj.find(
              (el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS ONLY',
            );
            const lwsHhd = updateState.ratesObj.find(
              (el: IRateDetail) => el.keyDefinition === 'HOUSEHOLD DISCOUNT=LWS AND HHD',
            );
            const hhd =
              userData.location!.state !== 'FL' && userData.location!.state !== 'OH'
                ? lwsHhd!.rateAmount - lws!.rateAmount + base!.rateAmount
                : base!.rateAmount;
            finalVal += percentMath(hhd);
          } else if (updateState.ratesObj && updateState.ratesObj.find((el: IRateDetail) => el.keyDefinition === key)) {
            finalVal += percentMath(
              updateState.ratesObj?.find((el: IRateDetail) => el.keyDefinition === key)!.rateAmount,
            );
          }
        });
        return finalVal;
      };
      if (updateState.lowestRateObj && updateState.hasDiscounts) {
        const keyDefinition = updateState.lowestRateObj?.keyDefinition;
        const keyString = (keyDefinition && keyDefinition.split('=')[1]) || '';
        const SeIncluded = keyString.includes('SE');
        const HhdIncluded = keyString.includes('HHD') || keyString.includes('YES');
        const LwsIncluded = keyString.includes('LWS');
        const allIncluded = keyString.includes('ALL');
        const noKeyString = keyString.includes('NO');
        const premium: Array<string> = [];
        if (HhdIncluded || allIncluded) {
          if (!['HI'].includes(userData.location!.state)) {
            premium.push('HOUSEHOLD DISCOUNT=YES');
          }
        }
        if (LwsIncluded || allIncluded) {
          if (!['AZ', 'DE', 'GA', 'HI', 'IL', 'IA', 'ME', 'MS', 'OK', 'TX'].includes(userData.location!.state)) {
            premium.push('HOUSEHOLD DISCOUNT=LWS ONLY');
          }
        }
        if (noKeyString || LwsIncluded) {
          if (['CT', 'FL', 'NJ', 'OH'].includes(userData.location!.state)) {
            premium.push('HOUSEHOLD DISCOUNT=LWS AND HHD');
          }
        }
        let finalDiscountPercent;
        const replaceDiscountText = (text: string) => {
          if (['KS', 'WA', 'ND', 'PA', 'OH'].includes(userData.location!.state)) {
            return text.replaceAll('${discountText}', suppContent.t('misc.rate'));
          } else {
            return text.replaceAll('${discountText}', suppContent.t('misc.discount'));
          }
        };
        if ((premium! && SeIncluded) || allIncluded) {
          const premiumDiscount = convertPercent(premium!);
          const selfEnrollDiscount = ['KS', 'WA', 'ND', 'PA', 'OH'].includes(userData.location!.state)
            ? 0
            : convertPercent(['HOUSEHOLD DISCOUNT=SE ONLY']);
          let premiumDiscountContent;
          if (userData.location?.state === 'TX') {
            premiumDiscountContent = suppContent
              .t('stateInfo.premiumIncludesTx')
              .replace('${premiumDiscount}', premiumDiscount.toString())
              .replace('${selfEnrollDiscount}', selfEnrollDiscount.toString());
          } else if (userData.location?.state === 'WY') {
            premiumDiscountContent = suppContent
              .t('misc.premiumSEIncludesWY')
              .replace('${premiumDiscount}', premiumDiscount.toString())
              .replace('${selfEnrollDiscount}', selfEnrollDiscount.toString());
          } else if (['AZ', 'GA'].includes(userData.location!.state) && Number(userData.age) < 90) {
            premiumDiscountContent = suppContent
              .t('misc.premiumSEIncludesAZGA')
              .replace('${premiumDiscount}', premiumDiscount.toString())
              .replace('${selfEnrollDiscount}', selfEnrollDiscount.toString());
          } else if (premiumDiscount === 0) {
            premiumDiscountContent = suppContent
              .t('misc.SEOnlyIncludes')
              .replace('${selfEnrollDiscount}', selfEnrollDiscount.toString());
          } else if (selfEnrollDiscount === 0) {
            premiumDiscountContent = suppContent
              .t('misc.premiumOnlyIncludes')
              .replace('${premiumDiscount}', premiumDiscount.toString());
          } else {
            premiumDiscountContent = suppContent
              .t('misc.premiumSEIncludes')
              .replace('${premiumDiscount}', premiumDiscount.toString())
              .replace('${selfEnrollDiscount}', selfEnrollDiscount.toString());
          }
          premiumDiscountContent = replaceDiscountText(premiumDiscountContent);
          updateState.discountIncludesHtml = <p className="self-enroll">{premiumDiscountContent}</p>;
          finalDiscountPercent = convertPercent(premium!)! + convertPercent(['HOUSEHOLD DISCOUNT=SE ONLY'])!;
        } else if (premium!) {
          const premiumDiscount = convertPercent(premium!);
          let premiumDiscountContent;
          if (['KS', 'WA', 'ND', 'PA'].includes(userData.location!.state)) {
            premiumDiscountContent = suppContent
              .t('misc.premiumRateSEHardcoded')
              .replace('${premiumDiscount}', premiumDiscount.toString());
          } else if (userData.location?.state === 'TX') {
            premiumDiscountContent = suppContent
              .t('stateInfo.premiumIncludesTx')
              .replace('${premiumDiscount}', premiumDiscount.toString());
          } else {
            premiumDiscountContent = suppContent
              .t('misc.premiumOnlyIncludes')
              .replace('${premiumDiscount}', premiumDiscount.toString());
          }
          premiumDiscountContent = replaceDiscountText(premiumDiscountContent);
          updateState.discountIncludesHtml = <p className="self-enroll">{premiumDiscountContent}</p>;
          finalDiscountPercent = convertPercent(premium!);
        } else {
          const selfEnrollDiscount = convertPercent(['HOUSEHOLD DISCOUNT=SE ONLY']);
          let premiumDiscountContent = suppContent
            .t('misc.SEOnlyIncludes')
            .replace('${selfEnrollDiscount}', selfEnrollDiscount.toString());
          premiumDiscountContent = replaceDiscountText(premiumDiscountContent);
          updateState.discountIncludesHtml = <p className="self-enroll">{premiumDiscountContent}</p>;
          finalDiscountPercent = convertPercent(['HOUSEHOLD DISCOUNT=SE ONLY']);
        }
        const discountContent = suppContent
          .t('misc.totalDiscount')
          .replace('${finalDiscountPercent}', finalDiscountPercent.toString());
        updateState.discountHtml = (
          <leaf-button-link
            classes="leaf-u-link--inverted"
            ismodaltoggle={true}
            data-modal-id={`discount-modal-${props.data.productID}`}
            data-custom="prop13:ac23987^eVar119:CAKTD-4646^eVar120:shop-plans-premium-discount"
          >
            {discountContent}
          </leaf-button-link>
        );
      }
      updateState.planCost = updateState.premiumAfterRider ? updateState.premiumAfterRider : monthlyRate;
      updateState.supRate = formatPremium(updateState.planCost);
      updateState.monthlyPremium = suppContent.t('misc.monthlyPremium').split('${monthlyPremium}');
    } else {
      updateState.planCost = props.data.MedicalPremium + props.data.DrugPremium;
      updateState.selectedPlanType = props.data.PlanType;
      updateState.selectedId = props.data.ID;
      updateState.selectedPlanId = props.data.PlanID;
      updateState.selectedContractId = props.data.ContractID;
      updateState.selectedSegmentId = props.data.SegmentID;
      updateState.plan = props.data;
      updateState.plan!.planName = props.data.PlanName;

      compareHtml = (
        <form>
          <leaf-checkgroup required name="compareplan" id="compareplan" variant="rows">
            <leaf-checkbox
              id={`${props.data.ID}-compare`}
              value={suppContent.t('misc.comparePlans')}
              data-planid={props.data.ID}
              tabindex="0"
              data-custom="prop13:ac23988^eVar119:CSTD-3125^eVar120:shop-plans-compare-plan-checkbox"
            >
              {suppContent.t('misc.comparePlans')}
            </leaf-checkbox>
          </leaf-checkgroup>
        </form>
      );
    }

    updateState.compareHtml = compareHtml;
    updateState.hideEnroll =
      (userData.year?.toString() === '2024' && content.t('misc.hideEnroll') == 'FALSE') ||
      (userData.year?.toString() === '2025' && content.t('misc.hideEnroll') == 'TRUE')
        ? 'hideEnroll'
        : '';
    setPlanCardState(updateState);

    return () => {
      document.removeEventListener('modalEnrolltoggle', modalEnrollToggle);
    };
  }, []);

  return (
    <div className="plan-card-wrapper" data-plantype={props.planType}>
      {planCardState.plan && (
        <chc-igse-product-card ref={rootRef} data-impression={setImpressionStr(planCardState.plan)}>
          <h4 slot="heading">
            {props.planType !== 'supp' ? (
              <leaf-button-link
                className="plan-name"
                onClick={viewDetails}
                data-custom="prop13:ac23982^eVar119:CSTD-3125^eVar120:shop-plans-view-details"
              >
                <span className="plan-heading plan-heading-btn">
                  {HTMLReactParser(`${planCardState.plan.planName}`)}
                </span>
              </leaf-button-link>
            ) : (
              <a
                className="plan-name leaf-u-link--external"
                target="_blank"
                href={planCardState.plan.detailsLink}
                data-custom="prop13:ac23982^eVar119:CSTD-3125^eVar120:shop-plans-view-details"
              >
                <span className="plan-heading">{HTMLReactParser(`${planCardState.plan.planName}`)}</span>
              </a>
            )}
          </h4>
          {props.planType !== 'supp' &&
            (!props.data.PlanRating || props.data.PlanRating === 0 ? (
              <div slot="header">
                <div>
                  {props.data.ContractID + '-' + props.data.PlanID + '-' + props.data.SegmentID}
                  <p className="rating">{content.t('misc.noRating')}</p>
                </div>
              </div>
            ) : (
              <div slot="header">
                <div>
                  {props.data.ContractID + '-' + props.data.PlanID + '-' + props.data.SegmentID}
                  <leaf-rating value={props.data.PlanRating} max="5">
                    <leaf-popover slot="popover">
                      <leaf-button-link slot="trigger" classes="leaf-u-link--no-underline">
                        <span className="visually-hidden">
                          {props.data.PlanRating} {content.t('misc.starRatingMax')}
                        </span>
                        {content.t('misc.starRating')}
                        <leaf-icon
                          class="popover-icon"
                          slot="icon-after"
                          set="system"
                          name="help"
                          aria-label="tooltip"
                          role="img"
                        ></leaf-icon>
                      </leaf-button-link>
                      <span slot="content">{content.t('misc.starRatingTooltip')}</span>
                    </leaf-popover>
                  </leaf-rating>
                </div>
              </div>
            ))}

          <div slot="cta-block">
            {(props.planType !== 'supp' || planCardState.monthlyPremium) && renderPremium(planCardState.plan)}
          </div>
          {planCardState.discountIncludesHtml}
          {/* Riders */}
          {planCardState.riderArray.length > 0 && (
            <div slot="medical-coverage" className="align-content-center riders no-med">
              <leaf-button-link
                classes="leaf-u-link--no-underline"
                type="action"
                ismodaltoggle={true}
                data-modal-id="rider-modal"
              >
                {suppContent.t('misc.maySelectRiders')}
                <leaf-icon set="system" name="keyboard-arrow-right"></leaf-icon>
              </leaf-button-link>
            </div>
          )}
          <div slot="content">{renderBenefits(planCardState.plan)}</div>
          {props.planType !== 'supp' && props.data.PlanType !== 1 && (
            <>
              <div slot="content-description">
                <p className="care-heading">{content.t('ma.primaryCopay')}</p>
                <p className="care-results">{HTMLReactParser(planCardState.benefits.primaryCare || '')}</p>
              </div>
              <div slot="content-description">
                <p className="care-heading">{content.t('ma.specialistCopay')}</p>
                <p className="care-results">{HTMLReactParser(planCardState.benefits.specialistCare || '')}</p>
              </div>
            </>
          )}
          {renderDrugInfo()}
          {content.t('providerInfo.showProviders') === 'TRUE' && props.planType === 'ma' && (
            <div slot="provider-info">{renderProviderInfo(props.data)}</div>
          )}
          {planCardState.compareHtml && (
            <div slot="footer" className="compare-section align-content-center">
              {planCardState.compareHtml}
            </div>
          )}
        </chc-igse-product-card>
      )}

      {planCardState.riderArray.length > 0 && (
        <RiderModal
          riderArray={planCardState.riderArray}
          allRates={props.data.rateQuote}
          monthlyMultiplier={planCardState.monthlyMultiplier}
          lowestRateObj={planCardState.lowestRateObj!}
          isEligibleBefore={props.isEligibleBefore}
          planCost={planCardState.planCost}
          onSaveRider={updatePremium}
        />
      )}
      {planCardState.ratesObj && planCardState.monthlyMultiplier && planCardState.lowestRateObj && (
        <leaf-modal
          has-focus-trap="true"
          role="dialog"
          aria-modal="true"
          id={`discount-modal-${props.data.productID}`}
          hasclosebutton=""
          size="md"
          scrollable=""
          data-en-content={suppContent.t('discountModal.header')}
          data-custom="prop13:ac23990^prop20:Premium Discounts^eVar119:CAKTD-4646^eVar120:shop-plans-premium-discount-modal-close"
        >
          <h3 slot="heading">{suppContent.t('discountModal.header')}</h3>
          <DiscountModal
            ratesObj={planCardState.ratesObj}
            monthlyMultiplier={planCardState.monthlyMultiplier}
            lowestRateObj={planCardState.lowestRateObj}
          />
        </leaf-modal>
      )}
      <EnrollWarningModal
        fullplan={`${props.data.ContractID}-${props.data.PlanID}-${props.data.SegmentID}`}
        planID={props.data.ID}
        enrollWarningContent={planCardState.enrollWarningContent || ''}
      />
    </div>
  );
};

export default PlanCard;
