import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import enOnlyContent from '../../../public/locales/en-only-content.json';
import enrollWarningPlans from '../../../public/locales/enroll-warning-plans.json';
import { currencyFormat } from '../../utils/currencyFormat.js';
import { environment } from '../../app.js';
import { enroDRX } from '../../utils/apiHelper/igse-session.js';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import useErrorStates from '../../hooks/use-error-states.js';
import './enroll.scss';
import { IPlanDetailResponse } from '../../utils/api/interfaces.js';
import HTMLReactParser from 'html-react-parser';
import useUserData from '../../hooks/use-user-data.js';
import { customTagEvent } from '../../utils/analytics/utils.js';

interface EnrollProps {
  userData: {
    year?: number;
  };
  headerOrFooter: string;
  renderModal?: Boolean;
  localState: {
    isExpired?: boolean;
    selectedPharmacy: IPlanDetailResponse;
  };
}

interface EnrollState {
  isEnrollSpinner: boolean;
}

interface IWarningObj {
  year: string;
  block: string[];
  medicaid: string[];
  diabetes: string[];
}

export const Enroll: FC<EnrollProps> = (props: EnrollProps) => {
  const planDetailsContent = useTranslation('plan-details-content');
  const content = useTranslation('plan-results-content');
  const pharmacyData = usePharmacyData();
  const userData = useUserData();
  const hasErrorState = useErrorStates();
  const currentYear = new Date().getFullYear();
  const [localState, setLocalState] = useState<EnrollState>({
    isEnrollSpinner: false,
  });

  const enrollModal = () => {
    const costPrem =
      props.localState.selectedPharmacy.planDetail.DrugPremium +
      props.localState.selectedPharmacy.planDetail.MedicalPremium;
    const fullPlan = `${props.localState.selectedPharmacy.planDetail.ContractID}-${props.localState.selectedPharmacy.planDetail.PlanID}-${props.localState.selectedPharmacy.planDetail.SegmentID}`;
    const totalPrem = currencyFormat(costPrem)?.replace('$', '');
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData!.year!.toString(),
    );
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });
    return (
      (content.t('misc.nextYearShowEnrollModal') === 'true' ||
        content.t('misc.currentYearShowEnrollModal') === 'true') &&
      warnModal.length < 1 && (
        <leaf-modal
          class="enroll-modal-details"
          id="EnrollThisPlanInViewDetails"
          size="md"
          data-impression={`plan-details^1^${props.localState.selectedPharmacy.planDetail.PlanName}^${
            props.localState.selectedPharmacy.planDetail.PlanType === 1
              ? 'Medicare Part D Plans'
              : 'Medicare Advantage Plans'
          }^${
            props.localState.selectedPharmacy.planDetail.ContractID +
            '-' +
            props.localState.selectedPharmacy.planDetail.PlanID +
            '-' +
            props.localState.selectedPharmacy.planDetail.SegmentID
          }^${totalPrem}`}
        >
          <h4 slot="heading">{planDetailsContent.t('modal.heading')}</h4>
          <div>
            {enrollContent()}
            {enroll()}
          </div>
          {enrollModalFooter()}
          {localState.isEnrollSpinner && (
            <leaf-spinner className="enroll-spinner" role="alert" aria-live="assertive" size="large">
              <span>{content.t('misc.loading')}</span>
            </leaf-spinner>
          )}
        </leaf-modal>
      )
    );
  };

  const dismissModal = (isRemove: boolean) => {
    if (isRemove) {
      if (document?.querySelector('#EnrollThisPlanInViewDetails')) {
        window.scrollTo(0, 0);
      }
    }
    (document?.querySelector('#EnrollThisPlanInViewDetails') as any)?.hide();
  };

  const enrollDrx = (event: Event, data: string) => {
    const target = event.target as HTMLElement;
    const planType = props.localState.selectedPharmacy.planDetail.PlanType !== 1 ? 'ma' : 'pdp';
    customTagEvent(target, `${planType}-plan-click`);
    setLocalState((prevState) => ({ ...prevState, isEnrollSpinner: true }));
    enroDRX({
      session: props.userData,
      pharmacyList: pharmacyData.getPharmacyList(),
      selectedPlan: data,
    }).catch(() => {
      hasErrorState.setPlanDetailsEnroll(true);
    });
  };

  const enrollButton = (headerOrFooter = 'footer') => {
    const isCurrentYear = userData.year?.toString() === currentYear.toString();
    const fullPlan = `${props.localState.selectedPharmacy.planDetail.ContractID}-${props.localState.selectedPharmacy.planDetail.PlanID}-${props.localState.selectedPharmacy.planDetail.SegmentID}`;
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData!.year!.toString(),
    );
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });
    switch (true) {
      case warnModal.length < 1 && isCurrentYear && content.t('misc.currentYearShowEnroll') === 'true':
      case warnModal.length < 1 && !isCurrentYear && content.t('misc.nextYearShowEnroll') === 'true':
      case environment?.showAllEnroll && warnModal.length < 1:
        return (
          <div className="enroll">
            <leaf-button
              data-en-content={enOnlyContent.plansView.enrollModalOpen}
              data-custom={`prop13:ac24002^prop20:${headerOrFooter}^eVar119:CSTD-3132^eVar120:plan-details-enroll-in-plan-drx-exit^eVar160:Exit*${environment.endpoints.drxUrl}`}
              variant="primary"
              onClick={(event: Event) => enrollDrx(event, props.localState.selectedPharmacy.planDetail.ID)}
            >
              {planDetailsContent.t('misc.buttonCopy')}
            </leaf-button>
          </div>
        );
      case isCurrentYear && content.t('misc.currentYearShowEnrollModal') === 'true':
      case isCurrentYear && content.t('misc.currentYearShowEnroll') === 'true' && warnModal.length > 0:
      case !isCurrentYear && content.t('misc.nextYearShowEnroll') === 'true' && warnModal.length > 0:
      case !isCurrentYear && content.t('misc.nextYearShowEnrollModal') === 'true':
      case environment?.showAllEnroll:
        return (
          <div className="enroll">
            <leaf-button
              varriant="primary"
              data-modal-id={warnModal.length > 0 ? `enroll-warning-modal-${fullPlan}` : 'EnrollThisPlanInViewDetails'}
              onClick={() => (headerOrFooter = headerOrFooter)}
              data-en-content={enOnlyContent.plansView.enrollModalOpen}
              data-custom="prop13:ac24779^eVar119:CSTD-3132^eVar120:link-plan-details-enrollment-confirmation-modal-open"
            >
              {planDetailsContent.t('misc.buttonCopy')}
            </leaf-button>
          </div>
        );
      default:
        return '';
    }
  };

  const enrollContent = () => {
    const thisContent = planDetailsContent.t('modal', { returnObjects: true });
    if (props.localState.isExpired) {
      if (props.localState.selectedPharmacy.planDetail.PlanType === 1 && thisContent.transferNewPDPPlan) {
        return <p>{HTMLReactParser(thisContent.transferNewPDPPlan)}</p>;
      } else if (!(props.localState.selectedPharmacy.planDetail.PlanType === 1) && thisContent.transferNewMAPlan) {
        return <p>{HTMLReactParser(thisContent.transferNewMAPlan)}</p>;
      }
    } else {
      if (props.localState.selectedPharmacy.planDetail.PlanType === 1 && thisContent.transferPDPPlan) {
        return <p>{HTMLReactParser(thisContent.transferPDPPlan)}</p>;
      } else if (!(props.localState.selectedPharmacy.planDetail.PlanType === 1) && thisContent.transferPlan) {
        return <p>{HTMLReactParser(thisContent.transferPlan)}</p>;
      }
    }
    return;
  };

  const enroll = () => {
    return <p>{planDetailsContent.t('modal.confirmMessage')}</p>;
  };

  const enrollModalFooter = () => {
    return (
      <>
        <div slot="cta">
          <leaf-flex-container>
            <leaf-button
              class="enroll-btn"
              data-custom="prop13:ac24780^prop20:Plan Enrollment Confirmation^eVar119:CSTD-3132^eVar120:link-plan-details-enrollment-confirmation-modal-confirm-exit^eVar160:exit*${environment.endpoints.drxUrl}"
              data-en-content={enOnlyContent.plansView.enrollExit.replace('${planYear}', userData.year)}
              variant="primary"
              onClick={(event: Event) => enrollDrx(event, props.localState.selectedPharmacy.planDetail.ID)}
            >
              {' '}
              {planDetailsContent.t('modal.yesEnroll').replace('${year}', userData.year)}
            </leaf-button>
            <leaf-button
              variant="tertiary"
              class="cancel-button"
              onClick={dismissModal}
              data-custom="prop13:ac24781^prop20:Plan Enrollment Confirmation^eVar119:CSTD-3132^eVar120:link-plan-details-enrollment-confirmation-cancel-modal-close"
              data-en-content={enOnlyContent.plansView.enrollModalCancel}
            >
              {planDetailsContent.t('modal.noCancel')}
            </leaf-button>
          </leaf-flex-container>
        </div>
      </>
    );
  };
  return (
    <>
      {props.renderModal && enrollModal()}
      {enrollButton(props.headerOrFooter)}
    </>
  );
};
